import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikType, DefaultFormikValues } from './VehicleActions.type';
import { DropdownModel } from 'models/VehicleType.type';
import {
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
    InputAdornment,
    TableContainer,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import {
    CustomAutocomplete,
    CustomBox,
    CustomTextfield,
    HubBayContainer,
    HubBayIconLock,
    SingleVehicleConfig,
    StyledTable,
    StyledTableHead,
    VehicleLayouContent,
    VehicleLayoutCover,
    VehicleLayoutLabel
} from './VehicleActions.style';
import { useRecoilValue } from 'recoil';
import { VehicleActionAtom } from 'states/component/Vehicle';
import { CRUD } from 'variables';
import UiIconPicker from '../../Ui/Components/UiIconPicker/UiIconPicker';
import { VehicleModelModel } from 'models/VehicleModel.type';
import { useTranslation } from 'react-i18next';
import Schema from '../NewVehicleSchema';
import { UserInfo } from 'states/global/User';
import useConverter from '../../CustomHooks/Converter/Converter';
import ExternalSystemsInfo from './ExternalSystemsInfo';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { DeviceModel } from 'models/Device.type';
import { Manufacturer } from 'models/VehicleManufacturer.type';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { applyStyleByMode, isVehicleTyreBayHub } from 'helpers';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { getImgSource } from 'helpers/image';

export const VehicleActionsFormik: React.FC<FormikType> = (props): JSX.Element => {
    const vehicleActionAtom = useRecoilValue(VehicleActionAtom);
    const basicColors: string[] = ['Darkgrey', 'White', 'Red', 'Blue', 'Yellow'];
    const { t: translate } = useTranslation();
    const manufacturerOptions = props.getManufacturerOptions();
    const userSettings = useRecoilValue(UserInfo);
    const { displayUserUnits } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo]);
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    const showTemperatureSettings = useMemo(() => {
        return (
            checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]) &&
            customerSettings.temperature_prediction
        );
    }, [userInfo, customerSettings.temperature_prediction]);

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').required(translate('t.required')),
        odometer: Yup.number().required(translate('t.required')),
        model: Yup.mixed()
            .notRequired()
            .when('manufacturer.id', {
                is: (val) => typeof val === 'number',
                then: Yup.object()
                    .shape({
                        id: Yup.number(),
                        modelName: Yup.string(),
                        vehicleConfigurationId: Yup.string(),
                        vehicleManufacturerId: Yup.string(),
                        vehicleTypeId: Yup.string()
                    })
                    .required(translate('t.required')),
                otherwise: Yup.mixed().notRequired()
            }),
        hub: Yup.mixed()
            .notRequired()
            .when('model.id', {
                is: 9,
                then: Yup.mixed().required(translate('t.required')),
                otherwise: Yup.mixed().notRequired()
            }),
        externalSystems:
            isSuperAdmin && (vehicleActionAtom.action === CRUD.EDIT || vehicleActionAtom.action === CRUD.CLONE)
                ? Yup.array().of(
                      Yup.object().shape({
                          externalSystemId: Yup.number(),
                          externalSystemName: Yup.string(),
                          value: Yup.string().notRequired()
                      })
                  )
                : Yup.array(),
        predictionTemperatureCriticalThreshold: Yup.number()
            .required(translate('t.required'))
            .test('decimal-places', translate('t.is_not_in_correct_format'), (value) => {
                if (value === undefined) {
                    return false;
                }
                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            })
    });

    return (
        <Formik
            initialValues={props.defaultFormikValues}
            validationSchema={formValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
        >
            {(formikProps) => {
                const vehicleTyreBayHubVerified =
                    isVehicleTyreBayHub(formikProps.values.model.id) &&
                    formikProps.values.model.vehicleManufacturerId === 11;

                return (
                    <Form id='vehicle-action-form'>
                        <Grid container padding={1} spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomTextfield
                                    data-testid='vehicle-name'
                                    id='vehicle-name'
                                    name='name'
                                    error={
                                        (!!formikProps.errors.name && formikProps.touched.name) || props.isNameUnique
                                    }
                                    label={translate('t.name')}
                                    value={formikProps.values.name}
                                    onBlur={() => {
                                        !props.hasOnlySpaces(formikProps.values.name) &&
                                            props.checkUniquenessOfVehicleName(formikProps.values.name);
                                    }}
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    helperText={
                                        props.isNameUnique
                                            ? translate('p.vehicle_name_exist')
                                            : formikProps.errors.name && formikProps.touched.name
                                            ? formikProps.errors.name
                                            : ''
                                    }
                                    onChange={formikProps.handleChange}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {!vehicleTyreBayHubVerified && (
                                    <>
                                        <CustomTextfield
                                            data-testid='vehicle-odometer'
                                            id='vehicle-odometer'
                                            label={translate('t.odometer')}
                                            value={formikProps.values.odometer}
                                            variant='outlined'
                                            size='small'
                                            name='odometer'
                                            error={!!formikProps.errors.odometer && !!formikProps.touched.odometer}
                                            type='number'
                                            fullWidth
                                            onChange={formikProps.handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        {(userSettings &&
                                                            userSettings?.user?.userSetting?.distanceUnit) ||
                                                            displayUserUnits.speed}
                                                    </InputAdornment>
                                                )
                                            }}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={formikProps.errors.odometer}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8} lg={8}>
                                                <CustomAutocomplete
                                                    disableClearable
                                                    data-testid='vehicle-type-select'
                                                    id='vehicle-type-select'
                                                    size='small'
                                                    value={formikProps.values.vehicleType}
                                                    options={props.vehicleTypes ?? []}
                                                    getOptionLabel={(option) => (option as DropdownModel).type ?? ''}
                                                    onChange={(event, newValue) => {
                                                        formikProps.setFieldValue('vehicleType', newValue, false);
                                                        formikProps.setFieldValue(
                                                            'maxSpeed',
                                                            (newValue as DefaultFormikValues).maxSpeed,
                                                            false
                                                        );
                                                        formikProps.setFieldValue(
                                                            'icon',
                                                            `${(newValue as DefaultFormikValues).icon}`,
                                                            false
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={translate('t.vehicle_group')}
                                                            variant='outlined'
                                                            size='small'
                                                            key={params.id}
                                                            name={params.id}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <CustomTextfield
                                                    data-testid='vehicle-max-speed'
                                                    id='vehicle-max-speed'
                                                    label={translate('t.max_speed')}
                                                    value={formikProps.values.maxSpeed}
                                                    variant='outlined'
                                                    size='small'
                                                    name='maxSpeed'
                                                    type='number'
                                                    fullWidth
                                                    disabled={formikProps.values.inheritMaxSpeed}
                                                    onChange={formikProps.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {(userSettings &&
                                                                    userSettings?.user?.userSetting?.speedUnit) ||
                                                                    displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                <Grid container spacing={2} style={{ marginTop: '-16px' }}>
                                    {!vehicleTyreBayHubVerified && (
                                        <Grid item xs={12} md={3} lg={3}>
                                            <UiIconPicker
                                                defaultIcon={`${formikProps.values.icon}`}
                                                data={[
                                                    {
                                                        group: 'mining/48x48/adt1_Yellow.png',
                                                        colors: basicColors
                                                    },
                                                    {
                                                        group: 'mining/48x48/adt2_Yellow.png',
                                                        colors: basicColors
                                                    },
                                                    {
                                                        group: 'mining/48x48/adt_Yellow.png',
                                                        colors: basicColors
                                                    },
                                                    {
                                                        group: 'mining/48x48/dumptruck_Yellow.png',
                                                        colors: basicColors.concat(['Pink', 'Rainbow'])
                                                    },
                                                    {
                                                        group: 'mining/48x48/grader_Yellow.png',
                                                        colors: basicColors
                                                    },
                                                    {
                                                        group: 'mining/48x48/loader_Yellow.png',
                                                        colors: basicColors
                                                    },
                                                    {
                                                        group: 'mining/48x48/undeground_Yellow.png',
                                                        colors: basicColors
                                                    }
                                                ]}
                                                style={{
                                                    margin: '8px',
                                                    marginLeft: '0'
                                                }}
                                                onIconSelect={(icon) => {
                                                    formikProps.setFieldValue('icon', icon, false);
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={9} lg={9}>
                                        <CustomTextfield
                                            data-testid='vehicle-comment'
                                            id='vehicle-comment'
                                            label={translate('t.comment')}
                                            name='comment'
                                            multiline
                                            rows={3}
                                            value={formikProps.values.comment}
                                            onChange={formikProps.handleChange}
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {vehicleActionAtom.action === CRUD.EDIT && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 6 }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={
                                                    <Switch
                                                        data-testid='out-of-service-switch'
                                                        color='primary'
                                                        checked={formikProps.values.inheritMaxSpeed}
                                                        value={formikProps.values.inheritMaxSpeed}
                                                        name='showTargetPressure'
                                                    />
                                                }
                                                onChange={(e, value) => {
                                                    formikProps.setFieldValue('inheritMaxSpeed', value, false);
                                                }}
                                                label={translate('t.apply_maximum_speed')}
                                                labelPlacement='end'
                                            />
                                            {formikProps.values.inheritMaxSpeed && (
                                                <Typography>
                                                    {translate('p.values_can_be_changed_in_vehicle_type')}
                                                </Typography>
                                            )}
                                        </div>
                                        <FormControlLabel
                                            value={formikProps.values.enableTemperatureCheckbox}
                                            control={
                                                <Switch
                                                    data-testid='out-of-service-switch'
                                                    color='primary'
                                                    checked={formikProps.values.enableTemperatureCheckbox}
                                                    value={formikProps.values.enableTemperatureCheckbox}
                                                    name='showTargetPressure'
                                                />
                                            }
                                            onChange={(e, value) => {
                                                formikProps.setFieldValue('enableTemperatureCheckbox', value, false);
                                            }}
                                            label={translate('t.temperature_on_speed_alerts')}
                                            labelPlacement='end'
                                        />
                                        <Typography>{translate('t.max_speed_for_temperature')}</Typography>

                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextfield
                                                    data-testid='max-speed-temperature-1'
                                                    id='maxSpeedTemperatureL1'
                                                    name='maxSpeedTemperatureL1'
                                                    error={
                                                        !!formikProps.errors.maxSpeedTemperatureL1 &&
                                                        formikProps.touched.maxSpeedTemperatureL1
                                                    }
                                                    label={`${translate('t.level')} 1`}
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    type='number'
                                                    defaultValue={0}
                                                    value={formikProps.values.maxSpeedTemperatureL1}
                                                    disabled={
                                                        !formikProps.values.enableTemperatureCheckbox ||
                                                        formikProps.values.inheritMaxSpeed
                                                    }
                                                    helperText={
                                                        formikProps.errors.maxSpeedTemperatureL1 &&
                                                        formikProps.touched.maxSpeedTemperatureL1
                                                            ? formikProps.errors.maxSpeedTemperatureL1
                                                            : ''
                                                    }
                                                    onChange={formikProps.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {(userSettings &&
                                                                    userSettings?.user?.userSetting?.speedUnit) ||
                                                                    displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextfield
                                                    data-testid='max-speed-temperature-2'
                                                    id='maxSpeedTemperatureL2'
                                                    name='maxSpeedTemperatureL2'
                                                    error={
                                                        !!formikProps.errors.maxSpeedTemperatureL2 &&
                                                        formikProps.touched.maxSpeedTemperatureL2
                                                    }
                                                    label={`${translate('t.level')} 2`}
                                                    variant='outlined'
                                                    size='small'
                                                    defaultValue={0}
                                                    value={formikProps.values.maxSpeedTemperatureL2}
                                                    disabled={
                                                        !formikProps.values.enableTemperatureCheckbox ||
                                                        formikProps.values.inheritMaxSpeed
                                                    }
                                                    fullWidth
                                                    type='number'
                                                    helperText={
                                                        formikProps.errors.maxSpeedTemperatureL2 &&
                                                        formikProps.touched.maxSpeedTemperatureL2
                                                            ? formikProps.errors.maxSpeedTemperatureL2
                                                            : ''
                                                    }
                                                    onChange={formikProps.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {(userSettings &&
                                                                    userSettings?.user?.userSetting?.speedUnit) ||
                                                                    displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextfield
                                                    data-testid='max-speed-temperature-3'
                                                    id='maxSpeedTemperatureL3'
                                                    name='maxSpeedTemperatureL3'
                                                    error={
                                                        !!formikProps.errors.maxSpeedTemperatureL3 &&
                                                        formikProps.touched.maxSpeedTemperatureL3
                                                    }
                                                    label={`${translate('t.level')} 3`}
                                                    variant='outlined'
                                                    size='small'
                                                    defaultValue={0}
                                                    value={formikProps.values.maxSpeedTemperatureL3}
                                                    disabled={
                                                        !formikProps.values.enableTemperatureCheckbox ||
                                                        formikProps.values.inheritMaxSpeed
                                                    }
                                                    fullWidth
                                                    type='number'
                                                    helperText={
                                                        formikProps.errors.maxSpeedTemperatureL3 &&
                                                        formikProps.touched.maxSpeedTemperatureL3
                                                            ? formikProps.errors.maxSpeedTemperatureL3
                                                            : ''
                                                    }
                                                    onChange={formikProps.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {(userSettings &&
                                                                    userSettings?.user?.userSetting?.speedUnit) ||
                                                                    displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {vehicleTyreBayHubVerified && (
                                    <HubBayContainer>
                                        <Grid
                                            container
                                            alignContent='center'
                                            alignItems='center'
                                            justifyContent='center'
                                        >
                                            <Grid md={8}>
                                                <img
                                                    width={40}
                                                    alt='img'
                                                    src={getImgSource('hub/48x48/tyre_bay_hub.png')}
                                                />
                                            </Grid>
                                            <Grid md={4}>
                                                <HubBayIconLock>
                                                    <FontAwesomeIcon
                                                        size='1x'
                                                        icon={faLock}
                                                        color={applyStyleByMode({
                                                            theme: ThemeMode?.mode,
                                                            light: '#0000008a',
                                                            dark: WHITE
                                                        })}
                                                    />
                                                </HubBayIconLock>
                                            </Grid>
                                        </Grid>
                                    </HubBayContainer>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomAutocomplete
                                    disableClearable
                                    id='vehicle-manufacturer-select'
                                    data-testid='vehicle-manufacturer-select'
                                    size='small'
                                    value={formikProps.values.manufacturer}
                                    isOptionEqualToValue={(option, value) => {
                                        return (option as Manufacturer).id === (value as Manufacturer).id;
                                    }}
                                    options={
                                        props.vehicleManufacturerData?.codeBook
                                            .filter(
                                                (option) =>
                                                    manufacturerOptions.includes(option.id) ||
                                                    vehicleActionAtom.action === CRUD.ADD
                                            )
                                            .sort((a, b) =>
                                                a.manufacturer > b.manufacturer
                                                    ? 1
                                                    : b.manufacturer > a.manufacturer
                                                    ? -1
                                                    : 0
                                            ) ?? []
                                    }
                                    getOptionLabel={(option) => (option as { manufacturer: string }).manufacturer ?? ''}
                                    onChange={(event, newValue) => {
                                        if (props.vehicleModelData) {
                                            formikProps.setFieldValue(
                                                'model',
                                                props.vehicleModelData.codeBook.filter(
                                                    (model: VehicleModelModel) =>
                                                        model.vehicleManufacturerId ===
                                                            (newValue as { id: number }).id &&
                                                        (vehicleActionAtom.action !== CRUD.ADD
                                                            ? model?.vehicleConfigurationId ===
                                                              formikProps.values.model.vehicleConfigurationId
                                                            : true)
                                                )[0] ?? [],
                                                false
                                            );
                                        }
                                        formikProps.setFieldValue('manufacturer', newValue, false);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.manufacturer')}
                                            variant='outlined'
                                            size='small'
                                            key={params.id}
                                            name={params.id}
                                        />
                                    )}
                                />
                                <CustomAutocomplete
                                    disableClearable
                                    id='vehicle-model-select'
                                    data-testid='vehicle-model-select'
                                    size='small'
                                    value={formikProps.values.model}
                                    options={
                                        props.vehicleModelData?.codeBook
                                            .filter(
                                                (model: VehicleModelModel) =>
                                                    model.vehicleManufacturerId ===
                                                        formikProps.values.manufacturer.id &&
                                                    (vehicleActionAtom.action === CRUD.ADD ||
                                                        model.vehicleConfigurationId ===
                                                            formikProps.values.model.vehicleConfigurationId)
                                            )
                                            .sort((a, b) =>
                                                a.modelName > b.modelName ? 1 : b.modelName > a.modelName ? -1 : 0
                                            ) ?? []
                                    }
                                    getOptionLabel={(option) => (option as VehicleModelModel).modelName ?? ''}
                                    onChange={(event, newValue) => {
                                        formikProps.setFieldValue('model', newValue, false);
                                    }}
                                    style={{ marginTop: 16 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.model')}
                                            variant='outlined'
                                            size='small'
                                            key={params.id}
                                            name={params.id}
                                            error={!!formikProps.errors.model && !!formikProps.touched.model}
                                            helperText={
                                                !!formikProps.errors.model && !!formikProps.touched.model
                                                    ? translate('t.required')
                                                    : ''
                                            }
                                        />
                                    )}
                                />

                                {props.availableDevices != null && (
                                    <CustomAutocomplete
                                        $withoutLabel
                                        data-testid='available-hubs-select'
                                        id='available-hubs-select'
                                        size='small'
                                        options={props.availableDevices?.codeBook ?? []}
                                        value={formikProps.values.hub}
                                        getOptionLabel={(option) => (option as DeviceModel).serialNumber}
                                        onChange={(event, newValue) => {
                                            formikProps.setFieldValue('hub', newValue, false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.hub')}
                                                variant='outlined'
                                                size='small'
                                                style={{
                                                    marginTop: 8
                                                }}
                                                key={params.id}
                                                name={params.id}
                                                error={!!formikProps.errors.hub && formikProps.touched.hub}
                                                helperText={
                                                    formikProps.errors.hub && formikProps.touched.hub
                                                        ? formikProps.errors.hub
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                )}
                                {isSuperAdmin &&
                                    (vehicleActionAtom.action === CRUD.EDIT ||
                                        vehicleActionAtom.action === CRUD.CLONE) &&
                                    formikProps.values.externalSystems.length > 0 && (
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <StyledTable size='small'>
                                                    <StyledTableHead>
                                                        <TableRow>
                                                            <TableCell>{translate('t.external_system')}</TableCell>
                                                            <TableCell>
                                                                {translate('t.key_pairing_value')}
                                                                <ExternalSystemsInfo />
                                                            </TableCell>
                                                        </TableRow>
                                                    </StyledTableHead>
                                                    <TableBody>
                                                        {formikProps.values.externalSystems.map(
                                                            (externalSystem, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        {externalSystem.externalSystemName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <CustomTextfield
                                                                            id={`external-system-for-${externalSystem.externalSystemName}`}
                                                                            data-testid={`external-system-for-${externalSystem.externalSystemName}`}
                                                                            size='small'
                                                                            style={{ margin: 0 }}
                                                                            fullWidth
                                                                            placeholder={translate('t.required')}
                                                                            onBlur={formikProps.handleBlur}
                                                                            value={externalSystem.value}
                                                                            onChange={(event) => {
                                                                                let newValues =
                                                                                    formikProps.values.externalSystems;
                                                                                newValues[index].value =
                                                                                    event.target.value;
                                                                                formikProps.setFieldValue(
                                                                                    'externalSystems',
                                                                                    newValues,
                                                                                    true
                                                                                );
                                                                            }}
                                                                            error={
                                                                                !!(formikProps.errors.externalSystems
                                                                                    ? formikProps.errors
                                                                                          .externalSystems[index]
                                                                                    : false) &&
                                                                                !!(formikProps.touched.externalSystems
                                                                                    ? formikProps.touched
                                                                                          .externalSystems[index]
                                                                                    : false)
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </StyledTable>
                                            </TableContainer>
                                        </Grid>
                                    )}
                                <Grid container spacing={2}>
                                    <Grid item md={6} lg={6}>
                                        <CustomBox $height='77px'>
                                            <VehicleLayoutLabel>{translate('t.service_settings')}</VehicleLayoutLabel>
                                            {vehicleActionAtom.action === CRUD.EDIT && (
                                                <FormControlLabel
                                                    value={formikProps.values.outOfService}
                                                    control={
                                                        <Switch
                                                            data-testid='out-of-service-switch'
                                                            color='primary'
                                                            checked={formikProps.values.outOfService}
                                                            value={formikProps.values.outOfService}
                                                            name='showTargetPressure'
                                                        />
                                                    }
                                                    label={translate('t.out_of_service')}
                                                    labelPlacement='end'
                                                    onChange={(e, value) => {
                                                        formikProps.setFieldValue('outOfService', value, false);
                                                    }}
                                                />
                                            )}
                                        </CustomBox>
                                    </Grid>
                                    {showTemperatureSettings && (
                                        <Grid item md={6} lg={6}>
                                            <CustomBox $height='160px'>
                                                <VehicleLayoutLabel>
                                                    {translate('t.temperature_settings')}
                                                </VehicleLayoutLabel>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            data-testid='predicted-alert-switch'
                                                            color='primary'
                                                            checked={formikProps.values.predictionTemperature}
                                                            value={formikProps.values.predictionTemperature}
                                                            name='showTargetPressure'
                                                        />
                                                    }
                                                    value={formikProps.values.predictionTemperature}
                                                    onChange={(e, value) => {
                                                        if (!value) {
                                                            formikProps.setFieldValue(
                                                                'genericTemperatureAlert',
                                                                true,
                                                                false
                                                            );
                                                        }
                                                        formikProps.setFieldValue(
                                                            'predictionTemperature',
                                                            value,
                                                            false
                                                        );
                                                    }}
                                                    label={translate('t.predicted_alert')}
                                                    labelPlacement='end'
                                                    disabled={!isSuperAdmin}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            data-testid='generic-alert-switch'
                                                            color='primary'
                                                            checked={formikProps.values.genericTemperatureAlert}
                                                            value={formikProps.values.genericTemperatureAlert}
                                                            name='showTargetPressure'
                                                            disabled={
                                                                !formikProps.values.predictionTemperature &&
                                                                formikProps.values.genericTemperatureAlert
                                                            }
                                                        />
                                                    }
                                                    value={formikProps.values.genericTemperatureAlert}
                                                    onChange={(e, value) => {
                                                        formikProps.setFieldValue(
                                                            'genericTemperatureAlert',
                                                            value,
                                                            false
                                                        );
                                                    }}
                                                    label={translate('t.standart_alert')}
                                                    labelPlacement='end'
                                                />
                                                <CustomTextfield
                                                    data-testid='threshold-field'
                                                    id='predictionTemperatureCriticalThreshold'
                                                    name='predictionTemperatureCriticalThreshold'
                                                    error={!!formikProps.errors.predictionTemperatureCriticalThreshold}
                                                    label={translate('t.threshold')}
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    type='number'
                                                    defaultValue={
                                                        formikProps.values.predictionTemperatureCriticalThreshold
                                                    }
                                                    value={formikProps.values.predictionTemperatureCriticalThreshold}
                                                    disabled={
                                                        !formikProps.values.predictionTemperature || !isSuperAdmin
                                                    }
                                                    helperText={
                                                        formikProps.errors.predictionTemperatureCriticalThreshold
                                                            ? formikProps.errors.predictionTemperatureCriticalThreshold
                                                            : ''
                                                    }
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.01,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </CustomBox>
                                        </Grid>
                                    )}
                                </Grid>
                                {false && vehicleActionAtom.action === CRUD.EDIT && (
                                    <CustomAutocomplete
                                        $withoutLabel
                                        id='available-device-displays-select'
                                        size='small'
                                        options={/*props.availableDisplays?.codeBook ??*/ []}
                                        //value={formikProps.values.deviceDisplay}
                                        getOptionLabel={(option) => (option as DeviceModel).serialNumber}
                                        onChange={formikProps.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.display')}
                                                variant='outlined'
                                                size='small'
                                                key={params.id}
                                                name={params.id}
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        {!vehicleTyreBayHubVerified && (
                            <Grid container paddingLeft={1}>
                                <Grid item>
                                    <VehicleLayouContent>
                                        <VehicleLayoutCover>
                                            <VehicleLayoutLabel>
                                                {translate('t.vehicle_configuration')}
                                            </VehicleLayoutLabel>
                                            <SingleVehicleConfig>
                                                <Schema
                                                    emptySchema={true}
                                                    schema={
                                                        props.getVehicleConfiguration(
                                                            formikProps.values.model.vehicleConfigurationId
                                                        ) || '2-2'
                                                    }
                                                />
                                            </SingleVehicleConfig>
                                        </VehicleLayoutCover>
                                    </VehicleLayouContent>
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
