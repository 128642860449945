import React, { useEffect } from 'react';
import * as view from './StatisticsMap.view';
import * as type from './StatisticsMap.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DateTimePicker, SelectedVehicle } from '../../states/global/Statistics';
import { MapAction } from '../../states/global/Map';
import { MapActionsEnum } from '../Map/Map.type';
import { UserInfo } from '../../states/global/User';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const StatisticsMap: React.FC<type.StatisticsMapProps> = (): JSX.Element => {
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const setMapAction = useSetRecoilState(MapAction);
    const userInfo = useRecoilValue(UserInfo);

    useEffect(() => {
        setTimeout(() => {
            setMapAction({
                action: MapActionsEnum.LOAD_JOURNEY_LINE_ON_STATISTICS,
                params: {
                    dateFrom: DateTime.fromMillis(statisticsDateTime.current.dateFrom.valueOf())
                        .setZone(userInfo?.user?.userSetting?.timezone?.timezoneName || 'Europe/Bratislava')
                        .toUTC()
                        .toFormat('yyyy-MM-dd HH:mm'),
                    dateTo: DateTime.fromMillis(statisticsDateTime.current.dateTo.valueOf())
                        .setZone(userInfo?.user?.userSetting?.timezone?.timezoneName || 'Europe/Bratislava')
                        .toUTC()
                        .toFormat('yyyy-MM-dd HH:mm'),

                    vehicleId: selectedVehicle.id
                }
            });
        }, 300);
    }, [statisticsDateTime, selectedVehicle, setMapAction, userInfo?.user?.userSetting?.timezone?.timezoneName]);

    return <view.StatisticsMapContent data-testid='StatisticsMap-testid' />;
};

export default Wrapper(StatisticsMap);
