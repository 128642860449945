import React, { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import {
    CustomTableCell,
    GridCover,
    HubGeneralInfoContainer,
    SaveBtn,
    ShowSelfDiagnosticsBtn,
    StyledCardActions,
    StyledTextField,
    CustomTableContainer
} from './HubGeneralInfo.style';
import { HubGeneralInfoViewProps } from './HubGeneralInfo.type';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { UserInfo } from '../../../states/global/User';
import DeviceDiagnose from '../DeviceDiagnose/DeviceDiagnose';
import { Theme } from 'states/global/Theme';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import useModal from 'components/CustomHooks/ModalHook';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import SkeletonLoader from 'components/Ui/Components/UiTable2/SkeletonLoader/SkeletonLoader';

const isHotspot = (wifiChannel: string | null): string => {
    return +(wifiChannel || 0) > 0 ? 'on' : 'off';
};

export const HubGeneralInfoContent: React.FC<HubGeneralInfoViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCToTimezone } = useConverter();
    const refshowBtn = useRef<HTMLButtonElement>(null);
    const { setModalState } = useModal();
    const userIsAdmin = useMemo(
        () => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]),
        [userInfo.user, ROLES]
    );
    const userIsSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user, ROLES]);
    const ThemeState = useRecoilValue(Theme);

    const generateDetailsTable = useCallback(() => {
        if (props.hubData) {
            return (
                <CustomTableContainer $isLoading={props.isLoading}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {`${translate('t.hub')} ID`}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    <strong>{props.hubData.id ?? '-'}</strong>
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.mounted_in_vehicle')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    <UiLink
                                        content={props.hubData.vehicle?.name ?? '-'}
                                        title={translate('t.vehicle_detail')}
                                        url={
                                            props.hubData.vehicle
                                                ? `/vehicle-status?vehicle=${props.hubData.vehicle?.id}`
                                                : undefined
                                        }
                                        styleCustom={{ padding: '0' }}
                                        testId='HubGeneralInfo-LinkTo-VehicleStatus-Button'
                                    />
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.customer_name')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.customer?.name ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.mac_address')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.uuid ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.service')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.service ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.system_tools')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.tools ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.revision')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData?.deviceRevision?.name ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.firmware')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.fmw ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.sensor_types')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.seq ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.wifi_band')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.wifiBand}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.manufacturing_date')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.createdAt
                                        ? fromUTCToTimezone(props.hubData.createdAt || '', true)
                                        : '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.hub_age')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.createdAt ? (
                                        <>
                                            {`${Math.floor(
                                                DateTime.now().diff(DateTime.fromISO(props.hubData.createdAt), [
                                                    'years',
                                                    'months',
                                                    'days'
                                                ]).values.years
                                            )} ${translate('t.years')} `}
                                            {`${Math.floor(
                                                DateTime.now().diff(DateTime.fromISO(props.hubData.createdAt), [
                                                    'years',
                                                    'months',
                                                    'days'
                                                ]).values.months
                                            )} ${translate('t.months')} `}
                                            {`${Math.floor(
                                                DateTime.now().diff(DateTime.fromISO(props.hubData.createdAt), [
                                                    'years',
                                                    'months',
                                                    'days'
                                                ]).values.days
                                            )} ${translate('t.days')}`}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.channel')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.wifiChannel ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.hotspot')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.wifiChannel
                                        ? translate(`t.${isHotspot(props.hubData.deviceInfo.wifiChannel)}`)
                                        : '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {'IP'}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.ip ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.mask')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.mask ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.gateway')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.gateway ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    {translate('t.mount')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData.deviceInfo.mount ?? '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    Emmc
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData?.deviceInfo?.deviceEmmcStatus?.wearPerc
                                        ? `${props.hubData.deviceInfo.deviceEmmcStatus.wearPerc}%`
                                        : '-'}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    WiFi Mode
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData?.deviceInfo?.wifiMode}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    WiFi SSID
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData?.deviceInfo?.wifiSSID}
                                </CustomTableCell>
                            </TableRow>
                            <TableRow>
                                <CustomTableCell component='th' scope='row'>
                                    WiFi {translate('t.country')}
                                </CustomTableCell>
                                <CustomTableCell component='th' scope='row'>
                                    {props.hubData?.deviceInfo?.wifiCountry}
                                </CustomTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CustomTableContainer>
            );
        }
        return <SkeletonLoader customRowHeight={20} />;
    }, [props.hubData, props.isLoading]);

    return (
        <HubGeneralInfoContainer data-testid='HubGeneralInfoContent' isLoading={props.isLoading}>
            <Card variant='outlined'>
                <CardContent>
                    <GridCover container xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container item>
                                <Grid item xs={12} md={12} lg={12}>
                                    {generateDetailsTable()}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={12} lg={12} spacing={1}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Autocomplete
                                    options={props?.faultyReasons ? props.faultyReasons() : []}
                                    getOptionLabel={(option) =>
                                        translate(`t.${option.statusName.toLowerCase().replaceAll(' ', '_')}`)
                                    }
                                    multiple
                                    readOnly={!userIsAdmin}
                                    value={props.hubFaultyStatus}
                                    size='small'
                                    filterSelectedOptions
                                    inputValue=''
                                    onChange={props.changeFaultyStatus}
                                    renderInput={(params) => {
                                        return (
                                            <StyledTextField
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                                label={translate('t.hub_faulty_status')}
                                                size='small'
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <StyledTextField
                                    id='hub-status'
                                    multiline
                                    size='small'
                                    rows={3}
                                    label={translate('t.comment')}
                                    value={props.comment}
                                    onChange={props.changeComment}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !userIsAdmin }}
                                />
                            </Grid>
                        </Grid>
                    </GridCover>
                </CardContent>
                <StyledCardActions>
                    {userIsSuperAdmin && (
                        <>
                            <div>
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={translate('p.change_of_region_in_hub')}
                                    sx={{ mt: 2 }}
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </Tooltip>
                            </div>

                            <Grid item xs={6} md={4} lg={4} xl={3} justifySelf='flex-start'>
                                <Autocomplete
                                    options={props.appRegions || []}
                                    getOptionLabel={(option) => (option?.name ? option?.name.toUpperCase() : '')}
                                    onChange={(_e, newValue) => {
                                        const value = !newValue ? '' : newValue;

                                        props.changeAppRegion(value);
                                    }}
                                    size='small'
                                    disableClearable
                                    defaultValue={props.appRegionCurrent || undefined}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.region')}
                                            variant='outlined'
                                            name='appRegionId'
                                            value={props.appRegionCurrent || ''}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    )}
                    {userIsAdmin && (
                        <ShowSelfDiagnosticsBtn
                            loading={props.patchIsLoading}
                            onClick={() => {
                                setModalState({
                                    isOpen: true,
                                    content: <DeviceDiagnose id={props?.hubData?.id ?? 0} refshowBtn={refshowBtn} />,
                                    width: 600,
                                    height: ThemeState.bigFonts ? 505 : 605,
                                    leftTitle: translate('t.self_diagnostics_result')
                                });
                            }}
                        >
                            {translate('t.show_self_diagnostics_result')}
                        </ShowSelfDiagnosticsBtn>
                    )}

                    <SaveBtn
                        loading={props.patchIsLoading}
                        disabled={props.patchIsLoading}
                        $isVisible={userIsAdmin}
                        onClick={() => {
                            props.patchHub({
                                comment: props.comment,
                                faultyStatus: props.getUpdatedFaultyStatus(props.hubFaultyStatus)
                            });
                            props.appRegionCurrent && props.patchAppRegion(props.appRegionCurrent);
                        }}
                    >
                        {translate('t.save')}
                    </SaveBtn>
                </StyledCardActions>
            </Card>
        </HubGeneralInfoContainer>
    );
};
