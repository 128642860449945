import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';

import { PRIMARY } from '../../colors';

export const StyledButton = styled(LoadingButton)<{ skin?: string; textcolor?: string }>`
    &.MuiButton-textSecondary,
    &.MuiButton-outlinedSecondary {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `color: ${PRIMARY};`
            });
        }}
    }

    &.MuiButton-root {
        margin: 0px 5px;
        padding: 6px;
        ${(props) => props.textcolor && `color: ${props.textcolor} !important;`}
        :not(.Mui-disabled) {
            box-shadow: none;
        }
    }
`;
