import React from 'react';
import { VehicleStatusIconsContainer, IconContainer, IconWrapper, StatusIcon } from './VehicleStatusIcons.style';
import { VehicleStatusIconsViewProps } from './VehicleStatusIcons.type';
import { useRecoilValue } from 'recoil';
import { Grid } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import HubConnectionIndicator from '../HubConnectionIndicator/HubConnectionIndicator';
import { DateTime } from 'luxon';
import HubBatteryIndicator from '../HubBatteryIndicator/HubBatteryIndicator';
import { AlertsToDisplay } from '../VehicleStatusWidget/Components/VehicleStatusList/VehicleStatusList.type';
import GpsIndicator from '../VehicleStatusWidget/Components/GpsIndicator/GpsIndicator';
import {
    UiHighPressureIcon,
    UiLowPressureIcon
} from '../VehicleStats/Components/VehicleStatsRow/VehicleStatsRow.style';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';

export const VehicleStatusIconsContent: React.FC<VehicleStatusIconsViewProps> = ({
    data,
    displayAlerts,
    lastTemperaturePredictedAt
}): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const { device, vehicle, alert } = data;
    const { vehicleInfo, maintenance } = vehicle;
    const { battery, powerSource } = vehicleInfo;
    const displayAlertsIcons: AlertsToDisplay = displayAlerts(alert);

    const validateDeviceId = Boolean(device?.id);
    const isValidPowerSource: boolean = powerSource !== null && powerSource > 0 && powerSource <= 999;
    const shouldShowBattery: boolean = validateDeviceId || isValidPowerSource;
    const shouldHideStatus: boolean = !device?.id || !isValidPowerSource;

    return (
        <VehicleStatusIconsContainer data-testid='VehicleStatusIconsContent'>
            <Grid container>
                <IconContainer>
                    <IconWrapper>
                        <StatusIcon data-testid='VehicleBoxList-SignalStatusWrapper-id'>
                            <HubConnectionIndicator
                                lastTimeConnected={DateTime.fromISO(vehicleInfo.measuredAt)}
                                connectionType='wifi'
                            />
                        </StatusIcon>
                    </IconWrapper>
                </IconContainer>

                {maintenance ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon $hide={!maintenance} data-testid='VehicleBoxList-MaintenanceIcon-id'>
                                <UiIcon
                                    icon={['fas', 'wrench']}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {/* Temporary disabled for BE */}
                {/* {vehicleInfo.parkingTime > 0 ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $hide={!(vehicleInfo.parkingTime > 0)}
                                data-testid='VehicleBoxlist-ParkedIcon-id'
                            >
                                <UiIcon
                                    icon={['fas', 'parking']}
                                    fontSize='16px'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )} */}

                {shouldShowBattery && !shouldHideStatus ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon $hide={shouldHideStatus} data-testid='VehicleBoxList-BatteryWrapper-id'>
                                <HubBatteryIndicator battery={battery} powerSource={powerSource} />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {!validateDeviceId ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon $hide={validateDeviceId} data-testid='VehicleBoxList-HubWrapper-id'>
                                <UiIcon
                                    icon={['fas', 'window-close']}
                                    fontSize='16px'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {device?.deviceInfo.shutdown ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $hide={!device?.deviceInfo.shutdown}
                                data-testid='VehicleBoxList-ShutdownIconWrapper-id'
                            >
                                <UiIcon
                                    icon={['fas', 'power-off']}
                                    fontSize='16px'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {vehicle.genericTemperatureAlert && displayAlertsIcons.temperature ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $hide={!displayAlertsIcons.temperature}
                                $temperatureIcon
                                data-testid='VehicleBoxList-StatusIcon-temperature-id'
                            >
                                <UiIcon
                                    icon={['fas', 'temperature-three-quarters']}
                                    fontSize='16px'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#FF1616'
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {!vehicle.genericTemperatureAlert ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $temperatureIcon
                                data-testid='VehicleBoxList-StatusIcon-temperature-disabled-id'
                            >
                                <span className='fa-layers fa-fw'>
                                    <UiIcon
                                        icon={['fas', 'slash']}
                                        fontSize='16px'
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            dark: WHITE,
                                            light: '#787878'
                                        })}
                                    />
                                    <UiIcon
                                        icon={['fas', 'temperature-three-quarters']}
                                        fontSize='16px'
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            dark: WHITE,
                                            light: '#787878'
                                        })}
                                    />
                                </span>
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {displayAlertsIcons.highPressure ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $pressureIcon
                                $hide={!displayAlertsIcons.highPressure}
                                data-testid='VehicleBoxList-StatusIcon-highpressure-id'
                            >
                                <UiHighPressureIcon color='#FFF' />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {displayAlertsIcons.lowPressure ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $pressureIcon
                                $hide={!displayAlertsIcons.lowPressure}
                                data-testid='VehicleBoxList-StatusIcon-lowpressure-id'
                            >
                                <UiLowPressureIcon />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}

                {displayAlertsIcons.temperaturePrediction ? (
                    <IconContainer>
                        <IconWrapper>
                            <StatusIcon
                                $hide={!displayAlertsIcons.temperaturePrediction}
                                data-testid='VehicleBoxList-StatusIcon-prediction-id'
                            >
                                <UiIcon
                                    icon={['fas', 'chart-mixed-up-circle-currency']}
                                    size='sm'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#FF1616'
                                    })}
                                />
                            </StatusIcon>
                        </IconWrapper>
                    </IconContainer>
                ) : (
                    <></>
                )}
                <IconContainer>
                    <IconWrapper>
                        <StatusIcon
                            $hide={!lastTemperaturePredictedAt}
                            data-testid='VehicleBoxList-StatusIcon-prediction-period-id'
                        >
                            <TemperaturePredictionPeriodIcon
                                time={lastTemperaturePredictedAt}
                                predictionTemperature={data.vehicle.predictionTemperature}
                            />
                        </StatusIcon>
                    </IconWrapper>
                </IconContainer>
                <IconContainer>
                    <IconWrapper>
                        <StatusIcon data-testid='VehicleBoxList-GpsIconWrapper-id'>
                            <GpsIndicator
                                powerSource={vehicle.vehicleInfo.powerSource}
                                deviceId={device?.id || null}
                                lastGpsMeasuredAt={DateTime.fromISO(vehicle.vehicleInfo.lastGpsMeasuredAt)}
                                maintenance={vehicle.maintenance}
                                outOfService={vehicle.outOfService}
                                measuredAt={DateTime.fromISO(vehicle.vehicleInfo.measuredAt)}
                            />
                        </StatusIcon>
                    </IconWrapper>
                </IconContainer>
            </Grid>
        </VehicleStatusIconsContainer>
    );
};
