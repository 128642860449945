import { Circle, Geometry, LineString } from 'ol/geom';
import { JourneyData } from '../../../models/Vehicle.type';
import { CustomLayers, DefaultSettings, MapActions } from '../Map.type';
import Feature from 'ol/Feature';
import { easeIn } from 'ol/easing';
import { fromLonLat } from 'ol/proj';
import Map from 'ol/Map';
import { getCenter } from 'ol/extent';
import * as OlProj from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import VehicleApi from '../../../api/Vehicle';
import SensorsApi from '../../../api/Sensors';
import ExternalSystemMddBesTrackLogApi from '../../../api/ExternalSystemMddBesTrackLog';
import Vector from 'ol/source/Vector';
import { createVehicle } from './VehicleHandler';
import {
    DateFromToJourneyFaultyReportParams,
    DateFromToVehiclesMapConfig,
    MapActionDataType,
    RouteLineConfig,
    SlopeInclinationJourney,
    StatisticsLineConfig
} from '../../../states/global/Map';
import { RouteLocation } from '../../../models/Route.type';
import { TracklogHeatmapResponse } from '../../../models/ExternalSystemMddBesTrackLog.type';
import { Inspection, vehicleInspection } from 'models/Sensors.type';
import { BORDER_MAP_CURRENT_VEHICLE, MAP_CURRENT_VEHICLE } from 'components/Ui/colors';
import { Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import TrackLogAPI from 'api/TrackLog';
import { SlopeInclinationHeatmapData } from 'models/TrackLog.type';
import { getRGB, getSlopeRGB } from './HeatmapHandler';
import { MapBrowserEvent } from 'ol';

const Vehicle = new VehicleApi();
const Sensors = new SensorsApi();
const ExternalSystemMddBesTrackLog = new ExternalSystemMddBesTrackLogApi();
const trackLogAPI = new TrackLogAPI();

const createJourneyLine = (journeySettings: JourneyData[][], defaultSettings: DefaultSettings): Feature<Geometry> => {
    let journeyCoordinates: Coordinate[] = [];

    for (let i = 0, length = journeySettings.length; i < length; i++) {
        for (let j = 0, subLength = journeySettings[i].length; j < subLength; j++) {
            journeyCoordinates.push(
                OlProj.transform(
                    [journeySettings[i][j].longitude, journeySettings[i][j].latitude],
                    defaultSettings.epsg[0],
                    defaultSettings.epsg[1]
                )
            );
        }
    }

    const lineFeature = new Feature({
        geometry: new LineString(journeyCoordinates)
    });

    const lineStyle = new Style({
        stroke: new Stroke({
            color: 'rgba(0,0,255,1)',
            width: 3
        })
    });
    lineFeature.setStyle(lineStyle);

    return lineFeature;
};

const createSlopeInclinationJourneyLine = (
    journeySettings: SlopeInclinationHeatmapData,
    defaultSettings: DefaultSettings,
    mapActionData: MapActionDataType
): Feature<Geometry> => {
    let journeyCoordinates: Coordinate[] = [];

    journeyCoordinates.push(
        OlProj.transform(
            [journeySettings.longitudeFirst, journeySettings.latitudeFirst],
            defaultSettings.epsg[0],
            defaultSettings.epsg[1]
        )
    );
    journeyCoordinates.push(
        OlProj.transform(
            [journeySettings.longitude, journeySettings.latitude],
            defaultSettings.epsg[0],
            defaultSettings.epsg[1]
        )
    );

    const lineFeature = new Feature({
        geometry: new LineString(journeyCoordinates)
    });

    const color = mapActionData.useSlopeGradient
        ? getRGB(
              journeySettings.altSlopeAngleDegree,
              mapActionData.heatmapLimit?.min ?? 0,
              mapActionData.heatmapLimit?.max ?? 12
          )
        : getSlopeRGB(journeySettings.altSlopeAngleDegree);

    const lineStyle = [
        new Style({
            stroke: new Stroke({
                color,
                width: 3
            })
        })
    ];

    lineFeature.setProperties({
        slopeAngle: journeySettings.altSlopeAngleDegree,
        slopeSpeedAvg: journeySettings.speedAvg,
        slopeColor: color
    });

    lineFeature.setStyle(lineStyle);

    return lineFeature;
};

const createJourneyCircle = (
    journeyData: Inspection,
    vehicle: vehicleInspection,
    vehicleId: number,
    defaultSettings: DefaultSettings
) => {
    const point = new Feature({
        geometry: new Circle(
            OlProj.transform(
                [journeyData.longitude, journeyData.latitude],
                defaultSettings.epsg[0],
                defaultSettings.epsg[1]
            ),
            15
        )
    });

    const style = new Style({
        fill: new Fill({
            color: vehicle.id === vehicleId ? MAP_CURRENT_VEHICLE : 'rgb(255 234 0 / 77%)'
        }),
        stroke: new Stroke({
            color: vehicle.id === vehicleId ? BORDER_MAP_CURRENT_VEHICLE : 'rgb(225 207 0 / 77%)',
            width: 2
        })
    });

    point.setStyle(style);
    return point;
};

export const drawOnlyTheJourney = async (
    params: RouteLineConfig['params'],
    map: MapActions,
    storeGlobalData?: (data) => void,
    focus?: boolean,
    journeySource?: Vector<Geometry>
): Promise<void> => {
    const { route, afterAction } = params;

    afterAction && afterAction();
    if (route) {
        const journeyLayer = map.findLayerByName(CustomLayers.JOURNEY);
        const updatedJourneySource = journeySource ? journeySource : new Vector();
        const parseJourney = (journey: RouteLocation[]): JourneyData[] => {
            return journey.map((jour) => {
                return {
                    timeKey: jour.ts * 1000,
                    measuredAt: DateTime.fromSeconds(jour.ts).toFormat('YYYY-MM-DD HH:mm'),
                    longitude: jour.lon,
                    latitude: jour.lat
                };
            });
        };

        updatedJourneySource.addFeature(createJourneyLine([parseJourney(route)], map.defaultSettings));
        journeyLayer.length && journeyLayer[0].setSource(updatedJourneySource);

        const updateJourney = parseJourney(route);

        focus && map.focusToLayer(CustomLayers.JOURNEY);

        if (storeGlobalData) {
            storeGlobalData({ journey: updateJourney });
        }
    }
};

export const drawJourney = async (
    params: StatisticsLineConfig['params'],
    map: MapActions,
    storeGlobalData?: (data) => void,
    focus?: boolean,
    journeySource?: Vector<Geometry>,
    vehicleSource?: Vector<Geometry>
): Promise<void> => {
    const { vehicleId, dateFrom, dateTo, afterAction } = params;
    const journey = await Vehicle.getJourney(vehicleId, dateFrom, dateTo);
    afterAction && afterAction();
    if (journey) {
        const journeyLayer = map.findLayerByName(CustomLayers.JOURNEY);
        const updatedJourneySource = journeySource ? journeySource : new Vector();

        updatedJourneySource.addFeature(createJourneyLine(journey.journey, map.defaultSettings));
        journeyLayer.length && journeyLayer[0].setSource(updatedJourneySource);

        const vehicleLayer = map.findLayerByName(CustomLayers.VEHICLE);
        const updatedVehicleSource = vehicleSource ? vehicleSource : new Vector();
        const invalidVehicleSettings = Array.isArray(journey.lastPosition) && !journey.lastPosition.length;
        !invalidVehicleSettings &&
            updatedVehicleSource.addFeature(createVehicle(journey.lastPosition, map.defaultSettings));
        vehicleLayer.length && vehicleLayer[0].setSource(updatedVehicleSource);

        focus && map.focusToLayer(CustomLayers.JOURNEY);

        if (storeGlobalData) {
            const updateJourney = journey.journey
                .flat(10)
                .map((journey) => ({ ...journey, timekey: DateTime.fromISO(journey.measuredAt).toUnixInteger() }));
            storeGlobalData({
                journey: updateJourney,
                vehiclesPosition: { [vehicleId]: journey.lastPosition },
                vehicleId
            });
        }
    }
};

export const drawJourneys = (params: DateFromToVehiclesMapConfig['params'], map: MapActions): void => {
    const { dateFrom, dateTo, vehicleIds } = params;
    const journeySource = new Vector();
    const vehicleSource = new Vector();

    for (let i = 0, length = vehicleIds.length; i < length; i++) {
        drawJourney(
            {
                vehicleId: vehicleIds[i],
                dateFrom,
                dateTo
            },
            map,
            undefined,
            false,
            journeySource,
            vehicleSource
        );
    }
};

export const drawBesDrivingOnMap = (
    besData: TracklogHeatmapResponse['externalSystemMddBesTrackLog'],
    map: MapActions,
    zoom: number
): void => {
    const layer = map.findLayerByName(CustomLayers.JOURNEY);
    let source = new Vector();
    for (let i = 0, length = besData.length; i < length; i++) {
        const point = new Feature({
            geometry: new Circle(
                OlProj.transform(
                    [besData[i].longitude, besData[i].latitude],
                    map.defaultSettings.epsg[0],
                    map.defaultSettings.epsg[1]
                ),
                BesSettings[zoom].dotSize || 1
            )
        });

        const style = new Style({
            fill: new Fill({
                color: besData[i].drivingAssistance ? 'rgba(255,0,0,1)' : 'rgba(100,100,100,1)'
            }),
            stroke: new Stroke({
                color: besData[i].drivingAssistance ? 'rgba(255,0,0,1)' : 'rgba(100,100,100,1)',
                width: 1
            })
        });

        point.setStyle(style);
        source.addFeature(point);
    }

    layer && layer[0].setSource(source);
};

export const getBesSevereDriving = async (
    map: MapActions,
    mapActionData: MapActionDataType,
    storeGlobalData?: (data) => void
): Promise<void> => {
    if (storeGlobalData) {
        storeGlobalData({
            besHasMapData: false,
            besMapDataRequested: false,
            besMapData: [],
            besMapMode: true
        });
    }
    map.updateLoadingMapStatus(true);
    map.toggleMapInteractions();

    const bes = await ExternalSystemMddBesTrackLog.getHeatmap({
        area: (map?.getMapExtent() || [0, 0, 0, 0]).join(','),
        zoom: BesZoom[mapActionData.zoomLevel || 14]
    });
    const sortedData = bes.sort((a) => (!a.drivingAssistance ? -1 : 1));

    map.toggleMapInteractions();
    map.updateLoadingMapStatus(false);

    if (storeGlobalData) {
        storeGlobalData({
            besHasMapData: bes.length > 0,
            besMapDataRequested: true,
            besMapData: sortedData,
            besMapMode: true
        });
    }

    drawBesDrivingOnMap(sortedData, map, mapActionData.zoomLevel || 14);
};

export const drawJourneysCircle = async (
    params: DateFromToJourneyFaultyReportParams,
    map: MapActions,
    setWarningMsg: Dispatch<SetStateAction<string>>,
    warningMsg: string
) => {
    const journeyMapLayer = map.findLayerByName(CustomLayers.JOURNEY_FAULTY_REPORT);
    let updateMap = false;

    if (!params.measuredFrom || !params.measuredTo || !params.sensorId || !params.granularity) {
        return false;
    }

    if (journeyMapLayer.length) {
        const journeyMapSource = new Vector();
        const Inspections = await Sensors.getInspection({
            granularity: params.granularity,
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            sensorId: params.sensorId
        });

        if (Inspections.measurements.length) {
            Inspections.measurements.forEach((inspection) => {
                inspection.vehicles.forEach((vehicle) => {
                    const showthisVehicle = params.showAllVehicle ? true : vehicle.id === params.vehicleId;

                    if (showthisVehicle) {
                        updateMap = true;
                        journeyMapSource.addFeature(
                            createJourneyCircle(inspection, vehicle, params.vehicleId, map.defaultSettings)
                        );
                    }
                });
            });

            if (updateMap) {
                journeyMapLayer[0].setSource(journeyMapSource);

                map.mapInstance?.getView().animate({
                    center: fromLonLat(getCenter(journeyMapSource.getExtent()), 'EPSG:4326'),
                    duration: 1000,
                    easing: easeIn,
                    zoom: 17
                });

                setWarningMsg('');
            }
        }

        !updateMap && setWarningMsg(warningMsg);
    }
};

export const drawSlopeInclinationJourney = async (
    params: SlopeInclinationJourney,
    map: MapActions,
    mapActionData: MapActionDataType,
    setWarningMsg: Dispatch<SetStateAction<string>>
) => {
    map.updateLoadingMapStatus(true);
    const journey = await trackLogAPI.getSlopeInclinationHeatmap(params);

    if (journey?.slopeInclinationHeatmap?.length) {
        const journeyLayer = map.findLayerByName(CustomLayers.SLOPE_INCLINATION_JOURNEY);
        const updatedJourneySource = new Vector();

        journey.slopeInclinationHeatmap.forEach((data) => {
            updatedJourneySource.addFeature(
                createSlopeInclinationJourneyLine(data, map.defaultSettings, mapActionData)
            );
        });

        journeyLayer[0].setSource(updatedJourneySource);

        map.mapInstance?.getView().animate({
            center: fromLonLat(getCenter(updatedJourneySource.getExtent()), 'EPSG:4326'),
            duration: 1000,
            easing: easeIn,
            zoom: 14
        });

        setWarningMsg('');
    }
    map.updateLoadingMapStatus(false);
};

export const redrawSlopeInclinalJourney = (map: MapActions, mapActionData: MapActionDataType) => {
    const journeyLayer = map.findLayerByName(CustomLayers.SLOPE_INCLINATION_JOURNEY);
    const lala = journeyLayer[0].getSource()?.getFeatures();
    lala?.forEach((feature) => {
        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: mapActionData.useSlopeGradient
                        ? getRGB(
                              feature.getProperties().slopeAngle,
                              mapActionData.heatmapLimit?.min ?? 0,
                              mapActionData.heatmapLimit?.max ?? 12
                          )
                        : getSlopeRGB(feature.getProperties().slopeAngle),
                    width: 3
                })
            })
        );
    });
    journeyLayer[0].getSource()?.dispatchEvent('change');
};

const BesZoom = {
    11: 17,
    12: 17,
    13: 17,
    14: 17,
    15: 17,
    16: 17,
    17: 18,
    18: 18
};

const BesSettings = {
    11: {
        dotSize: 3
    },
    12: {
        dotSize: 3
    },
    13: {
        dotSize: 3
    },
    14: {
        dotSize: 3
    },
    15: {
        dotSize: 4
    },
    16: {
        dotSize: 5
    },
    17: {
        dotSize: 4
    },
    18: {
        dotSize: 3
    }
};

export const showSlopeInfo = (
    mapInstance: Map,
    e: MapBrowserEvent<any>,
    infoPopup: HTMLElement | null,
    storeGlobalData?: (data) => void
): void => {
    const currentPixel = mapInstance.getEventPixel(e.originalEvent);
    const features = e.originalEvent.target.closest('.ol-control')
        ? undefined
        : mapInstance.forEachFeatureAtPixel(currentPixel, (feature) => {
              return feature;
          });
    if (infoPopup) {
        if ((mapInstance.getView().getZoom() || 0) > 16.7) {
            // approx 100 m scale
            if (features) {
                infoPopup.style.left = `${currentPixel[0]}px`;
                infoPopup.style.top = `${currentPixel[1]}px`;
                const featureProperties = features.getProperties();
                storeGlobalData &&
                    storeGlobalData({
                        slopeAngle: featureProperties.slopeAngle,
                        slopeSpeedAvg: featureProperties.slopeSpeedAvg,
                        slopeColor: featureProperties.slopeColor
                    });
                if (featureProperties.slopeAngle) {
                    infoPopup.style.visibility = 'visible';
                }
            } else {
                infoPopup.style.visibility = 'hidden';
                storeGlobalData &&
                    storeGlobalData({
                        slopeAngle: undefined
                    });
            }
        } else {
            infoPopup.style.visibility = 'hidden';
            storeGlobalData &&
                storeGlobalData({
                    slopeAngle: undefined
                });
        }
    }
};
