import React, { useState, useMemo } from 'react';
import UiWidget from '../../components/Ui/Components/UiWidget';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import UiTable from '../../components/Ui/Components/UiTable2';
import Device from '../../api/Device';
import NewHubsExtendedRow from '../../components/Hub/NewHubsExtendedRow';
import { AllHubsTableData } from '../../models/Device.type';
import DeviceRevision from '../../api/DeviceRevision';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeviceRevisionCodeBook } from '../../models/DeviceRevision.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import UiLink from '../../components/Ui/Components/UiLink/UiLink';
import { DARK_STEEL_GREY, BACKGROUND_BLUE, LINK, WHITE } from '../../components/Ui/colors';
import { Theme } from '../../states/global/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_GREY_3, SUCCESS, ERROR } from '../../components/Ui/colors';
import { IconButton } from '@mui/material';
import DeviceCommand from '../../api/DeviceCommand';
import UiButton from '../../components/Ui/Components/UiButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UiMenu from '../../components/Ui/Components/UiMenu';
import { faDesktop } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { NoRevisionLabel } from './NewHubs.style';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { applyStyleByMode } from 'helpers';

const hubAPI = new Device();
const deviceRevisionAPI = new DeviceRevision();
const deviceCommandAPI = new DeviceCommand();

const testStatusColors = [COLOR_GREY_3, SUCCESS, ERROR];

const PERIODS_MINUTES: number[] = [60000, 120000, 300000, 900000, 1800000];

const NewHubs: React.FC = (): JSX.Element => {
    const [refreshTime, setRefreshTime] = useState<number>(
        Number(localStorage.getItem('new-hubs-refetch-interval')) || 120000
    );
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const list = useMemo(() => {
        return PERIODS_MINUTES.map((minutes) => {
            return {
                title: `${minutes / 60000}m`,
                value: minutes,
                action: setRefreshTime
            };
        });
    }, []);

    const mutation = useMutation<unknown, unknown, number>({
        mutationFn: (id: number) =>
            deviceCommandAPI.post({
                data: {
                    commandName: 'self_diagnostics',
                    value: null,
                    deviceIds: [id]
                }
            })
    });

    let { data } = useQuery(
        ['GetDeviceRevisionCodebook'],
        async () => {
            const data = await deviceRevisionAPI.get<DeviceRevisionCodeBook>({});
            return { codeBook: data.codeBook.sort((a, b) => b.id - a.id) };
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    const columns: Column<AllHubsTableData>[] = [
        {
            Header: `${translate('t.box')} ID`,
            accessor: 'id',
            Cell: (props: { value: number; row: { original: AllHubsTableData } }) => (
                <UiLink
                    testId='box-id'
                    $padding='0'
                    content={`${props.value}`}
                    url={`/device/${props.row.original.id}`}
                    color={applyStyleByMode({
                        styleOld: LINK,
                        theme: ThemeMode?.mode,
                        light: DARK_STEEL_GREY,
                        dark: BACKGROUND_BLUE
                    })}
                />
            )
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'serialNumber'
        },
        {
            Header: translate('t.hub_revision'),
            accessor: (row: AllHubsTableData) => row.deviceRevision?.name,
            id: 'deviceRevision.name',
            Cell: (props: { value: string; row: { original: AllHubsTableData } }) => (
                <>
                    {props.row.original?.deviceRevision?.name || (
                        <NoRevisionLabel>{translate('t.no_revision')}</NoRevisionLabel>
                    )}
                </>
            )
        },
        {
            Header: translate('t.latest_test_status'),
            accessor: 'testStatus',
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { value: number; row: { original: AllHubsTableData } }) => (
                <IconButton
                    data-testid='rerun-test-button'
                    onClick={() => {
                        mutation.mutate(props.row.original.id);
                    }}
                    title={translate('t.rerun_test')}
                >
                    <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: testStatusColors[props.value] }} />
                </IconButton>
            )
        },
        {
            Header: translate('t.battery_voltage'),
            accessor: (row: AllHubsTableData) => row.deviceInfo?.battery,
            id: 'deviceInfo.battery'
        },
        {
            Header: translate('t.date_of_last_test'),
            accessor: 'lastTestAt',
            disableSortBy: true,
            disableFilters: true,
            Cell: (props: { value: string | null; row: { original: AllHubsTableData } }) => {
                return (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.value,
                            format: 'dateTime',
                            displaySeconds: false,
                            displayIfEmpty: ''
                        })}
                    </>
                );
            }
        },
        {
            Header: 'UUID',
            accessor: 'uuid'
        },
        {
            Header: translate('t.first_connection'),
            accessor: 'createdAt',
            Cell: (props: { value: string }) => (
                <>
                    {fromUTCtoUserTimezone({
                        date: props.value,
                        format: 'dateTime'
                    })}
                </>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={`${translate('t.created_at_from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={`${translate('t.created_at_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.last_time_transmitted'),
            accessor: (row: AllHubsTableData) => row.deviceInfo?.lastTransmission,
            id: 'deviceInfo.lastTransmission',
            Cell: (props: { value: string }) => (
                <>
                    {fromUTCtoUserTimezone({
                        date: props.value,
                        format: 'dateTime'
                    })}
                </>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='LastTransmissionFrom'
                            keyFilter='lastTransmissionFrom'
                            label={`${translate('t.last_time_transmitted')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='LastTransmissionTo'
                            keyFilter='lastTransmissionTo'
                            label={`${translate('t.last_time_transmitted')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastTransmissionFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastTransmissionTo'
        }
    ];

    const expandableContent = (row: AllHubsTableData): JSX.Element => (
        <NewHubsExtendedRow hubData={row} revisionCodebook={data} />
    );

    return (
        <UiWidget
            testid='new-hubs-widget'
            title={translate('t.new_hubs')}
            avatar={
                <UiIcon
                    icon={faDesktop}
                    size='lg'
                    fixedWidth
                    color={applyStyleByMode({
                        styleOld: '#fff',
                        theme: ThemeMode?.mode,
                        light: undefined,
                        dark: WHITE
                    })}
                />
            }
            content={
                <UiTable
                    fetchFn={hubAPI.getNewHubsTable}
                    refresh={refreshTime}
                    columns={columns}
                    hiddenColumns={['createdAtFrom', 'createdAtTo', 'lastTransmissionFrom', 'lastTransmissionTo']}
                    queryKey={'NewHubs'}
                    isExpandable={true}
                    expandableContent={expandableContent}
                    defaultSortBy={{
                        id: 'id',
                        desc: true
                    }}
                    actionsButton={
                        <>
                            {translate('t.refresh_time')}:
                            <UiMenu
                                rootEl={
                                    <UiButton color='secondary' variant='text'>
                                        {refreshTime / 60000}m <ExpandMoreIcon />
                                    </UiButton>
                                }
                                items={list}
                            />
                        </>
                    }
                />
            }
        />
    );
};

export default Wrapper(NewHubs);
