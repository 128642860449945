import Feature from 'ol/Feature';
import Cluster from 'ol/source/Cluster';
import * as OlProj from 'ol/proj';
import {
    CustomLayers,
    DefaultSettings,
    drawHeatmapType,
    getHeatmapPropsType,
    getHeatmapResponseKey,
    HeatMapBasicProps,
    HeatmapData as HeatmapDataType,
    HeatmapDataResponse,
    HeatmapKeys,
    MapActions
} from '../Map.type';
import TrackLogApi from 'api/TrackLog';
import SensorLogApi from 'api/SensorLog';
import { MapActionDataType } from 'states/global/Map';
import { DateTime } from 'luxon';
import AccelerometerLogApi from 'api/AccelerometerLog';
import Style from 'ol/style/Style';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';

//let vehicles = 0; unused

export const getRGB = (value: number, minLimit = 0, maxLimit = 60, alpha = 1): string => {
    let min: number = minLimit;
    let max: number = maxLimit;
    const isReversed: boolean = minLimit > maxLimit;

    if (isReversed) {
        min = maxLimit;
        max = minLimit;
    }

    value = Math.min(Math.max(value, min), max);
    const percentage: number = min === 0 ? (value / max) * 100 : ((value - min) * 100) / (max - min);

    let G = 255;
    let R = 255;

    const colorValue: number = 5.1 * Math.abs(percentage - 50);

    if (isReversed ? percentage > 50 : percentage < 50) {
        R -= colorValue;
    } else if (isReversed ? percentage < 50 : percentage > 50) {
        G -= colorValue;
    }

    return `rgba(${R},${G},${0},${alpha})`;
};

export const getSlopeRGB = (value: number): string => {
    let color = 'rgb(255,0,0)';
    if (value < 12) {
        color = 'rgb(255,134,0)';
    }
    if (value < 6) {
        color = 'rgb(0,255,0)';
    }
    return color;
};

const TrackLog = new TrackLogApi();
const SensorLog = new SensorLogApi();
const AccelerometerLog = new AccelerometerLogApi();

const createHeatmap = (
    data: HeatmapDataType,
    defaultSettings: DefaultSettings,
    mapData?: MapActionDataType
): Feature[] => {
    let heatmapPoints: Feature[] = [];
    if (!mapData?.heatmapKey) {
        return heatmapPoints;
    }

    for (let i = 0, length = data.length; i < length; i++) {
        const value = data[i][mapData.heatmapKey];
        const featureId = `heatmap-${DateTime.valueOf()}-${data[i][mapData.heatmapKey]}`;
        let heatmapPointFeature = new Feature({
            id: featureId,
            geometry: new Point(
                OlProj.transform(
                    [data[i].longitude, data[i].latitude],
                    defaultSettings.epsg[0],
                    defaultSettings.epsg[1]
                )
            ),
            name: value,
            weight: value
        });

        /* let RGB: string = getRGB(value, mapData?.heatmapLimit?.min, mapData?.heatmapLimit?.max);
        var GradStyle = new Style({
            renderer: function (_coords, state) {
                const ctx = state.context;
                var x = +_coords[0],
                    y = +_coords[1],
                    innerRadius = mapData?.innerRadius ?? 5,
                    outerRadius = mapData?.outerRadius ?? 20,
                    radius = 50;
                var gradient = ctx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
                gradient.addColorStop(
                    0,
                    getRGB(value, mapData?.heatmapLimit?.min, mapData?.heatmapLimit?.max, mapData?.innerOpacity ?? 1)
                );
                gradient.addColorStop(
                    1,
                    getRGB(value, mapData?.heatmapLimit?.min, mapData?.heatmapLimit?.max, mapData?.outerOpacity ?? 0)
                );
                ctx.fillStyle = gradient;
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.closePath();
            }
        });*/

        /*  let styles = [
            new Style({
                image: new CircleStyle({
                    radius: 10,
                    fill: new Fill({
                        color: RGB
                    }),
                    stroke: new Stroke({
                        color: '#f00',
                        width: 2
                    })
                })
            })
        ];*/

        if (mapData?.enableBlur) {
            //styles.push(GradStyle);
        }

        //heatmapPointFeature.setStyle(GradStyle);
        heatmapPoints.push(heatmapPointFeature);
    }
    return heatmapPoints;
};

const getHeatmapProps: getHeatmapPropsType = (heatmapKey, mapData, map) => {
    const measuredFrom = mapData.heatmapDateRange?.dateFromUTC;
    const measuredTo = mapData.heatmapDateRange?.dateToUTC;
    if (measuredFrom && measuredTo && mapData.zoomLevel) {
        const basicProps: HeatMapBasicProps = {
            // need to solve this type
            measuredFrom,
            measuredTo,
            zoomLevel: mapData.zoomLevel,
            area: map?.getMapExtent().join(','),
            limit: 10000
        };
        if (
            heatmapKey === HeatmapKeys.GPS ||
            heatmapKey === HeatmapKeys.SPEED ||
            heatmapKey === HeatmapKeys.ACCELERATION ||
            heatmapKey === HeatmapKeys.VIBRATION ||
            heatmapKey === HeatmapKeys.VIBRATIONZ
        ) {
            basicProps.vehicleIds = mapData.vehicleIds?.join(',');
            //vehicles = mapData?.vehicleIds?.length || 0;
            //basicProps.limit = (mapData?.vehicleIds?.length || 0) > 0 ? 4800 : 9000;
        }
        if (heatmapKey === HeatmapKeys.SPEED) {
            basicProps.position = map?.getMapExtent();
            basicProps.vehicleIds = mapData.vehicleIds;
        }
        return basicProps;
    }
    return false;
};

const getHeatmapData = async (
    heatmapKey: HeatmapKeys,
    mapData: MapActionDataType,
    map: MapActions
): Promise<HeatmapDataResponse | boolean> => {
    switch (heatmapKey) {
        case HeatmapKeys.ELEVATION: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? TrackLog.getElevationHeatmap(props) : false;
        }
        case HeatmapKeys.GPS: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? TrackLog.getGPSHeatmap(props) : false;
        }
        case HeatmapKeys.SPEED: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? (TrackLog.getSpeedHeatmap(props) as HeatmapDataResponse) : false;
        }
        case HeatmapKeys.TEMPERATURE: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? SensorLog.getTemperatureHeatmap(props) : false;
        }
        case HeatmapKeys.ACCELERATION: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? TrackLog.getAccelerationHeatmap(props) : false;
        }
        case HeatmapKeys.VIBRATION: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? AccelerometerLog.getVibrationHeatmap(props) : false;
        }
        case HeatmapKeys.VIBRATIONZ: {
            const props = getHeatmapProps(heatmapKey, mapData, map);
            return props ? AccelerometerLog.getVibrationZHeatmap(props) : false;
        }
        //HEATMAP_DATA_SWITCH
        default:
            return false;
    }
};

export const drawHeatmap: drawHeatmapType = async ({ map, mapData }) => {
    if (!mapData.heatmapKey) {
        return;
    }
    const heatmapStyle = {};
    const heatmapLayer = map.findLayerByName(CustomLayers.HEATMAP);
    if (heatmapLayer.length) {
        map.updateLoadingMapStatus(true);
        map.toggleMapInteractions();
        //const heatmapSource = new Vector();
        const vectorSource = new VectorSource();
        const heatmapSource = new Cluster({
            distance: 3,
            minDistance: 3
        });
        const responseKey = getHeatmapResponseKey(mapData.heatmapKey);
        const zoom = Math.max(map.mapInstance?.getView().getZoom() || 13, 13);
        let data = await getHeatmapData(mapData.heatmapKey, { ...mapData, zoomLevel: zoom }, map);
        let dataFiltered = [];

        if (data)
            dataFiltered = mapData.heatmapKey === HeatmapKeys.SPEED ? data[responseKey] || [] : data[responseKey] || [];

        dataFiltered.sort((a, b) => {
            if (mapData.heatmapKey) {
                return a[mapData.heatmapKey] > b[mapData.heatmapKey] ? 1 : -1;
            }
            return -1;
        });

        map.updateLoadingMapStatus(false);
        map.toggleMapInteractions();
        if (dataFiltered.length) {
            let heatmapFeatures = createHeatmap(dataFiltered, map.defaultSettings, mapData);
            vectorSource.addFeatures(heatmapFeatures);
            heatmapSource.setSource(vectorSource);
            heatmapLayer[0].setSource(heatmapSource);
            heatmapLayer[0].setStyle((feature) => {
                const selectedStyle = CustomizedHeatmapSettings[zoom];

                const sum =
                    feature.get('features').reduce((acc, current) => acc + current.get('weight'), 0) /
                    feature.get('features').length;
                let style = heatmapStyle[`${sum}_${zoom}`];
                if (!style) {
                    style = new Style({
                        renderer: function (_coords, state) {
                            const ctx = state.context;
                            let x = +_coords[0],
                                y = +_coords[1],
                                innerRadius =
                                    (selectedStyle?.innerRadius ?? 5) *
                                    ((mapData?.zoomLevel || 13) < 17 ? 1 : devicePixelRatio),
                                outerRadius =
                                    (selectedStyle?.outerRadius ?? 20) *
                                    ((mapData?.zoomLevel || 13) < 17 ? 1 : devicePixelRatio) /*-
                                    vehicles * 3*/,
                                radius = 40;
                            let gradient = ctx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
                            gradient.addColorStop(
                                0.2,
                                getRGB(
                                    sum,
                                    mapData?.heatmapLimit?.min ?? 0,
                                    mapData?.heatmapLimit?.max ?? 20,
                                    selectedStyle?.innerOpacity ?? 1
                                )
                            );
                            /*gradient.addColorStop(
                                0.3,
                                getRGB(
                                    sum,
                                    mapData?.heatmapLimit?.min ?? 0,
                                    mapData?.heatmapLimit?.max ?? 20,
                                    (selectedStyle?.innerOpacity + (vehicles / 10) + (zoom < 15 ? 0.2 : 0)) ?? 1
                                )
                            );*/

                            gradient.addColorStop(
                                1,
                                getRGB(
                                    sum,
                                    mapData?.heatmapLimit?.min ?? 0,
                                    mapData?.heatmapLimit?.max ?? 20,
                                    selectedStyle?.outerOpacity ?? 0
                                )
                            );
                            ctx.fillStyle = gradient;
                            ctx.beginPath();
                            ctx.arc(x, y, radius, 0, 2 * Math.PI);
                            ctx.fill();
                            ctx.closePath();
                        }
                    });

                    heatmapStyle[`${sum}_${zoom}`] = style;
                }
                return style;
            });
        }
    }
};

const getRatio = (radius) => {
    //return devicePixelRatio > 1 ? radius * devicePixelRatio + (((radius / (devicePixelRatio + 1))) - (radius/4)) : radius - 1;
    return devicePixelRatio > 1 ? radius + devicePixelRatio * 0.7 - 0.4 : radius - 5;
};

const CustomizedHeatmapSettings = {
    13: {
        dotSize: 0,
        data: 6996,
        innerRadius: 0,
        outerRadius: getRatio(12),
        innerOpacity: 0.8,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    14: {
        dotSize: 0,
        data: 6996,
        innerRadius: 0,
        outerRadius: getRatio(13),
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    15: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: getRatio(13),
        innerOpacity: 0.6,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    16: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: getRatio(14),
        innerOpacity: 0.7 - (devicePixelRatio > 1 ? 0.1 : 0),
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    17: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: getRatio(14),
        innerOpacity: 0.7 - (devicePixelRatio > 1 ? 0.1 : 0),
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    18: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: getRatio(14),
        innerOpacity: 0.7 - (devicePixelRatio > 1 ? 0.1 : 0),
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    }
};
