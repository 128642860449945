import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    Content,
    DetailContent,
    DetailContentLine,
    DetailContentLinePressure,
    FontAwesomeIconWithPadding,
    TextGreen,
    TyreDetailsViewContent
} from './TyreDetails.style';
import { TyreDetailsViewProps } from './TyreDetails.type';
import { useTranslation } from 'react-i18next';
import { VehicleSchemaHoverAtom } from '../../Vehicle/VehicleDetailsSchema/VehicleDetailsSchema.atom';
import { VehicleDetailsActionMenuAtom } from '../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import AttachedSensors from './AttachedSensors';
import Pressure from './Pressure';
import Temperature from './Temperature';
import { SelectedSensorAtom } from './SelectedSensor.atom';

export const TyreDetailsContent: React.FC<TyreDetailsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [vehicleSchemaHoverAtom, setVehicleSchemaHoverAtom] = useRecoilState(VehicleSchemaHoverAtom());
    const setVehicleDetailsActionMenuAtom = useSetRecoilState(VehicleDetailsActionMenuAtom);
    const selectedSensor = useRecoilValue(SelectedSensorAtom(props.tyreDetails.wheel.position));
    const maxAlerts = props.getMaxAlerts(selectedSensor?.sensorAlerts);

    return (
        <TyreDetailsViewContent
            data-testid='TyreDetailsContent'
            className={vehicleSchemaHoverAtom.hoveredPosition === props.tyreDetails.wheel.position ? 'hovered' : ''}
            onMouseEnter={() => {
                setVehicleSchemaHoverAtom({
                    hoveredPosition: props.tyreDetails.wheel.position,
                    hasHover: true
                });
            }}
            onMouseLeave={() => {
                setVehicleSchemaHoverAtom({
                    hoveredPosition: 0,
                    hasHover: true
                });
            }}
            onClick={(event) => {
                setVehicleDetailsActionMenuAtom({
                    isOpen: true,
                    data: props.tyreDetails,
                    positionX: event.clientX,
                    positionY: event.clientY
                });
            }}
        >
            <Content>
                <DetailContent width={25} $firstColumn>
                    <DetailContentLine>
                        <span>
                            <TextGreen>
                                {translate('t.pos')} <strong>{props.tyreDetails.wheel.customPosition}</strong>
                            </TextGreen>
                        </span>
                    </DetailContentLine>
                    <DetailContentLine>
                        <span>
                            <TextGreen>
                                <FontAwesomeIconWithPadding paddingRight={3} icon={['fas', 'tire-rugged']} />
                                {props.tyreDetails.wheel.tyreSerialNumber || 'S/N'}
                            </TextGreen>
                        </span>
                    </DetailContentLine>
                    <DetailContentLine>
                        <span>
                            {!!props.tyreDetails.attachedSensors.length && (
                                <FontAwesomeIconWithPadding paddingRight={3} icon={['fas', 'search-plus']} />
                            )}
                            <AttachedSensors
                                attachedSensors={props.tyreDetails.attachedSensors}
                                wheelPosition={props.tyreDetails.wheel.position}
                            />
                        </span>
                    </DetailContentLine>
                </DetailContent>
                <DetailContent width={22}>
                    <DetailContentLine style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                        <Temperature
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.temperature}
                            temperaturePredictionAlert={maxAlerts.temperaturePrediction}
                            lastTemperaturePredictedAt={props.tyreDetails.wheel.lastTemperaturePredictedAt}
                            predictionTemperature={props.vehicleDetails?.predictionTemperature || false}
                        />
                    </DetailContentLine>
                </DetailContent>
                <DetailContent width={34}>
                    <DetailContentLinePressure>
                        <Pressure
                            displayingHotPressure={false}
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.pressure}
                        />
                    </DetailContentLinePressure>
                    <DetailContentLinePressure>
                        <Pressure
                            displayingHotPressure={true}
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.pressureHot}
                        />
                    </DetailContentLinePressure>
                </DetailContent>
                <DetailContent width={25}>
                    <DetailContentLine>
                        {props.showMeasuredAt(
                            selectedSensor?.sensorLog?.measuredAt || null,
                            props.tyreDetails.attachedSensors.length > 0
                        )}
                    </DetailContentLine>
                </DetailContent>
            </Content>
        </TyreDetailsViewContent>
    );
};
