import React from 'react';
import { VehicleStatusIconsContent } from './VehicleStatusIcons.view';
import { VehicleStatusIconsProps } from './VehicleStatusIcons.type';
import { Wrapper } from 'helpers/wrapper';

const VehicleStatusIcons: React.FC<VehicleStatusIconsProps> = ({
    data,
    displayAlerts,
    lastTemperaturePredictedAt
}): JSX.Element => {
    return (
        <VehicleStatusIconsContent
            data-testid='VehicleStatusIcons-testid'
            data={data}
            displayAlerts={displayAlerts}
            lastTemperaturePredictedAt={lastTemperaturePredictedAt}
        />
    );
};

export default Wrapper(VehicleStatusIcons);
